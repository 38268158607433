.toast {
    visibility: hidden;
    transform: translateX(-50%);
    margin: 0 auto;
    color: #888;
    text-align: center;
    border-radius: 5px;
    padding: 10px 18px;
    position: fixed;
    z-index: 1060;
    left: 50%;
    top: 30px;
  }
  // .toast {
  //   color: #37cc64;
  //   font-weight: 500;
  //   text-transform: uppercase;
  // }
  .toast.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 5s;
    animation: fadein 0.5s, fadeout 0.5s 5s;
  }
  
  @-webkit-keyframes fadein {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 30px;
      opacity: 1;
    }
  }
  
  @keyframes fadein {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 30px;
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeout {
    from {
      top: 30px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
  }
  
  @keyframes fadeout {
    from {
      top: 30px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
  }
  