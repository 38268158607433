.content-tbl {
  .ReactTable {
    border-radius: 0.25rem;
    font-size: 0.875rem;
    .rt-table {
      .rt-thead {
        font-weight: bold;
        .mc-input-search {
          width: fit-content;
        }
        input[type="date"] {
          min-width: 130px;
        }
        input[type="date"]::-webkit-inner-spin-button,
        input[type="date"]::-webkit-calendar-picker-indicator {
          // display: none;
          // -webkit-appearance: none;
          padding-left: 0;
          margin-left: 0;
          max-width: 20px;
        }
        &.-header {
          box-shadow: unset;
        }
        &.-filters {
          border: none;
          box-shadow: 0px 4px 4px rgba(182, 198, 205, 0.25);
          // box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
          .rt-tr {
            .rt-th {
              padding: 9px 15px;
              overflow: inherit;
              width: auto;
              display: flex;
              justify-content: center;
              .drop-down {
                // max-width: inherit;
              }
            }
          }
        }
      }
    }
  }
  .ReactTable .rt-tbody .rt-td {
    border: none;
    // margin: 5px;
    display: flex;
    justify-content: center;
    min-width: 0;
  }
  .rt-tbody {
    // max-height: calc(100vh - 400px);
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .input-search {
    width: 100px;
  }
  
}
