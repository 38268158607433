// Here you can add other styles
.c-sidebar-brand {
    padding: 0.8445rem 1rem;
    display: unset;
    &:hover {
        text-decoration: none;
    }
}

// .modal-dialog {
//     overflow-y: hidden !important;
//     max-height: calc(100vh - 56px);
//     .modal-body {
//         overflow-y: scroll !important;
//     }
// }

select::-ms-expand {
    display: none;
}

.custom-header {
    button.close {
        color: white !important;
        outline: none;
        font-size: 30px;
        font-weight: unset;
        text-shadow: unset;
        cursor: pointer !important;
        // opacity: unset;
    }
    button.close:hover {
        opacity: 1 !important;
    }
    > h5 {
        color: white !important;
    }
}

.forgot-password {
    .card-header {
        font-weight: bold;
    }
}
p.pointer-hover:hover {
    cursor: pointer;
}

.brand-title {
    font-size: large;
}

.loading-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 9999;
}

.form-passcode {
    display: flex;
    justify-content: space-around;
    .input-passcode {
        width: 65.16px;
        height: 91.45px;
        margin-right: 2px;
        text-align: center;
        color: rgb(124, 121, 121);
        font-weight: 500;
        font-size: 40px;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        box-shadow: 3px 4px 16px rgba(186, 190, 191, 0.19);
        border-radius: 6px;
        outline: none;
        &:focus {
            border-color: #9ecaed;
            box-shadow: 0 0 10px #9ecaed;
        }
    }
}

.drop-down {
    background-color: transparent;
    // min-width: 100px;
    // text-align: left;
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
    padding: 5px 7px;
    overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // display: inline-block;
}
.show {
    &.dropdown-toggle {
        color: unset;
        background-color: unset;
    }
}

.card-body {
    // overflow-y: scroll;
    // max-height: calc(100vh - 220px);
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
        margin: 20px 0;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #d8dbe0;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #c2c5ca;
    }
}

.modal-body.modal-info {
    padding-right: 2px;
}

.star {
    color: #ff6f62;
    padding-left: 5px;
}
svg.delete:hover {
    background-color: #e55353 !important;
    color: white !important;
    opacity: 1 !important;
    border-radius: 5px;
}

svg.edit:hover {
    background-color: #2eb85c !important;
    color: white !important;
    opacity: 1 !important;
    border-radius: 5px;
}
svg.info:hover {
    background-color: #39f !important;
    color: white !important;
    opacity: 1 !important;
    border-radius: 5px;
}
svg.refresh:hover {
    cursor: pointer;
}
// svg.delete {
//     color: red;
// }
// svg.edit {
//     color: #2eb85c;
// }
// svg.info {
//     color: #39f;
// }

input.search-date[type="date"]::-webkit-inner-spin-button,
input.search-date[type="date"]::-webkit-calendar-picker-indicator {
    padding-left: 0;
    margin-left: 0;
    max-width: 20px;
}

.form-control {
    &-xs {
        width: unset;
    }
}
// input[type="file"] {
//     border: 1px solid;
//     color: #768192;
//     background-color: #fff;
//     border-color: #d8dbe0;
//     border-radius: 0.25rem;
//     padding: 5px;
// }

// Custom input file
input[type="file"] {
    display: none;
}
.custom-file-upload {
    display: flex;
    height: calc(1.5em + 0.75rem + 2px);
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #d8dbe0;
    border-radius: 0.25rem;
    text-align: center;
    label {
        // padding: 5px;
        // margin: 0;
        &.file-name {
            color: #8a93a2;
            margin-left: 7px;
            font-size: 0.875rem;
            max-width: inherit;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        // &.select-file {
        //     background-color: #ebedef;

        //     &:hover {
        //         background-color: #39f !important;
        //         color: white !important;
        //         opacity: 1 !important;
        //     }
        // }
    }
}
.file-preview {
    margin: 0 10px;
}

img.thumbnail {
    &--sm {
        width: 40px;
        height: 40px;
        &:hover {
            cursor: pointer;
        }
    }
}

.td-action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
}

.focus {
    &--error {
        border-color: red;
    }
}
.action-header {
    display: flex;
    justify-content: flex-end;
}

.cover-hashtag {
    label {
        width: 100%;
        height: 35px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        display: flex;
        padding: 2px 5px;
        input {
            border: none;
            flex: 1;
            &:focus-visible {
                outline: none;
            }
        }
        span {
            background: #ccc;
            border-radius: 4px;
            padding: 3px 22px 3px 12px;
            position: relative;
            margin-right: 5px;
            button {
                position: absolute;
                padding: 0;
                top: 0px;
                right: 3px;
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.is-valid {
    display: block !important;
}

.bg-disable {
    background: #ccc;
}

.tooltip {
    div {
        background-color: #39f !important;
    }
    background-color: #39f !important;
}

div[id*="tippy"] {
    background-color: #39f !important;
    div {
        background-color: #39f !important;
    }
    .tippy-arrow {
        color: #39f !important;
    }
}

.w-inherit {
    width: inherit;
}

.btn {
    &-append {
        border-radius: 0 0.2rem 0.2rem 0;
    }
    &-prepend {
        border-radius: 0.2rem 0 0 0.2rem;
    }
}

.table {
    &-checkbox {
        .rt-thead {
            background-color: #39fe;
            color: white;
        }
        .rt-tr > div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
